import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ScrollAnimation from "react-animate-on-scroll";

const TestimonialOne = ({ column, teamStyle }) => {
    const [apiData, setApiData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get('https://new.whatson.agency/wp-json/wp/v2/testimonials?_fields=acf&acf_format=standard&_fields=id,title,acf')
            .then(response => {
                setApiData(response.data);
            })
            .catch(error => {
                console.error('Error fetching API data:', error);
                setError(error);
            });
    }, []);

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div className="row row--15">
            {apiData.map((data) => (
                <div className={`${column}`} key={data.id}>
                    <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                    >
                        <div className={`rn-box-card border-gradient ${teamStyle}`}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={data.acf.testimonial_image.url} alt="API Testimonial" />
                                     
                                </figure>
                                <figcaption className="content">
                                    <p className="description">{data.acf.testimonial_content}</p>
                                    <h2 className="title">{data.title.rendered}</h2>
                                    <h6 className="subtitle theme-gradient">{data.acf.designation}</h6>
                                </figcaption>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    );
};

export default TestimonialOne;
