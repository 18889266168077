import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FiCheck } from 'react-icons/fi';

const PriceList = () => {
  const [priceList, setPriceList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const categories = ['ecommerce-solution', 'laravel-ecommerce', 'php-ecommerce', 'codeigniter-ecommerce']; // Add multiple category names here
  const categoryIds = [24, 25, 27, 26]; // Add multiple category IDs here

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch all price list items with the required fields
        const url = 'https://new.whatson.agency/wp-json/wp/v2/price-list?_fields=id,title,acf&acf_format=standard&per_page=100';
        const response = await axios.get(url);
        
        // Filter data based on categories and category IDs
        const filteredData = response.data.filter(item => {
          const itemCategories = item.acf.package_category || [];
          return itemCategories.some(cat => categories.includes(cat) || categoryIds.includes(cat));
        });

        setPriceList(filteredData);
        setLoading(false);
        
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [categories, categoryIds]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className="row row--15">
      {priceList.length === 0 ? (
        <p>No price list items found for the selected categories.</p>
      ) : (
        priceList.map(price => (
          <div key={price.id} className="col-lg-4 col-md-6 col-12">
            <div className="rn-pricing style-5">
              <div className="pricing-table-inner">
                <div className="pricing-header">
                  <h4 className="title">{price.title?.rendered || 'No Title'}</h4>
                  <div className="pricing">
                    <div className="price-wrapper">
                    <span className="currency">{price.acf?.currency_symbol || 'N/A'}</span>
                      <span className="price">{price.acf?.package_price || 'N/A'}</span>
                     
                    </div>
                    <span className="subtitle">{price.acf?.price_recurring || 'N/A'}</span>
                  </div>
                </div>
                <div className="pricing-body">
                  <ul className="list-style--1">
                    {price.acf?.package_details ? (
                      price.acf.package_details.split('\r\n').map((detail, index) => (
                        <li key={index}>
                          <FiCheck /> {detail}
                        </li>
                      ))
                    ) : (
                      <li>No package details available</li>
                    )}
                  </ul>
                </div>
                <div className="pricing-footer">
                  <a className="btn-default btn-border" href={price.acf?.package_button_url || '#pricing'} target="_blank">Purchase Now</a>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default PriceList;
