import React, { useEffect, useState } from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import { Link } from "react-router-dom";
import { FiArrowRight , FiPlay } from "react-icons/fi";
import ScrollAnimation from "react-animate-on-scroll";
import axios from 'axios';



const Services = ({ textAlign, serviceStyle }) => {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get('https://new.whatson.agency/wp-json/wp/v2/services?_fields=acf&acf_format=standard&_fields=id,title,acf')
          .then(response => {
            setServices(response.data);
            setLoading(false);
          })
          .catch(error => {
            setError(error);
            setLoading(false);
          });
      }, []);
    
      if (loading) {
        return <p>Loading...</p>;
      }
    
      if (error) {
        return <p>Error: {error.message}</p>;
      }

    return (
        <div className="row row--15 service-wrapper" >
            {services.map(service => (

                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={service.id}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {service.acf.service_image && (
                                    <img
                                        src={service.acf.service_image}
                                        alt={service.title || 'Product Image'}
                                        style={{ maxWidth: '100%', height: 'auto' }}
                                    />
                                )}
                            </div>
                            <div className="content">
                                <h4 className="title w-600">{service.title?.rendered || 'No Title'}</h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: service.acf.service_summary }}></p>
                                {service.acf?.button_url && (
                                    <a className="btn-default btn-small mt--15" href={service.acf.button_url} target="_blank" rel="noopener noreferrer">See More</a>
                                )}
                            </div>
                        </div>
                    </ScrollAnimation>
                    
                </div>
                
            ))}
        </div>
    )
};

export default Services;